<template>
    <div class="card">
        <Toast />
        <h5>Assinatura de Gerentes</h5>

        <p>Envelope: {{envelope.idEnvelope}}</p>
        <p>Número Pedido: {{envelope.numeroPedido}}</p>
        <p>Data: {{envelope.dataCriacao}}</p>

        <hr/>
        TODO: Mostrar o PDF aqui.
        <hr/>

        <Button label="Assinar" icon="pi pi-times" class="p-button p-component p-button-info" @click="assinar()" />

        

    </div>
</template>

<script>
    
    import assinaturaGerenteService from '../../service/AssinaturaEletronica';

    export default {
        name: "AssinaturaGerente",
        props: {
            idAssinatura: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                
                //idAssinatura: null,
                loading: false,
                envelope: null,
            };
        },

        created() {
            this.consultarLinkAssinatura();
		},
		mounted() {
			this.loading1 = false;
		},
        methods: {
            carregarLoading() {
                this.loading = !this.loading;
            },
           
            consultarLinkAssinatura() {

                console.log(this.idAssinatura);
                
                assinaturaGerenteService.consultarEnvelopeId(this.idAssinatura)
                    .then(({ data }) => {
                         if (data) {
                            //console.log("DuvidasFrequentes: ", data);

                            console.log(`envelope`, data);

                            this.envelope = data;
                         }
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:"error",
                            detail: error.response.data.message_error, 
                            life: 5000
                        });

                        // if(error.response.data.code_error===403){
                        //     setTimeout(() => { 
                        //         this.$router.push('/');
                        //     }, 3000);
                            
                        // }
                    });
            },


        },
        components: {  }
}
</script>