<template>
    <AssinaturaGerenteIndex :idAssinatura="idAssinatura" />
</template>

<script lang="js">
    import AssinaturaGerenteIndex from '../../components/assinaturaEletronica/assinaturaGerente.vue'
    
    
    export default {
        name: 'assinaturaGerente',
        components: { AssinaturaGerenteIndex },
        data() {
            return {
                idAssinatura: null,
            };
        },
        created() {
            this.validarRotas();
        },
        updated() {
            this.validarRotas();
        },
        methods: {
            validarRotas() {
                //let nomeRota = this.$route.name;
                const const_idAssinatura = this.$route.params.idAssinatura;

                console.log(`constIdAssinatura: `, const_idAssinatura);

                this.idAssinatura = const_idAssinatura;
                
            },
            
        },
    }

</script>